import { useState, useMemo } from 'react';
import { CollectionCenterData } from '../services/admin/collectionCenter';

interface FilterState {
  repairWorkshop: boolean;
  tradeInStore: boolean;
  recyclingContainer: boolean;
}

export const useLocationFilters = (locations: CollectionCenterData[] | undefined) => {
  const [filters, setFilters] = useState<FilterState>({
    repairWorkshop: false,
    tradeInStore: false,
    recyclingContainer: false
  });

  const filteredLocations = useMemo(() => {
    return locations?.filter(location => {
      if (!filters.repairWorkshop && !filters.tradeInStore && !filters.recyclingContainer) {
        return true;
      }
      
      const hasNoServices = !location.repairWorkshop && !location.tradeInStore && !location.recyclingContainer;
      
      return (
        (filters.repairWorkshop && location.repairWorkshop) ||
        (filters.tradeInStore && location.tradeInStore) ||
        (filters.recyclingContainer && (location.recyclingContainer || hasNoServices))
      );
    });
  }, [locations, filters]);

  return { filters, setFilters, filteredLocations };
}; 