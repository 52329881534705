import { END_POINTS } from "../../helpers";
import emptySplitApi from "../../utils/rtk";

interface CollectionCenterLocation {
  type: string;
  coordinates: [number, number];
}

export interface CollectionCenterData {
  location: CollectionCenterLocation;
  name: string;
  latitude: number;
  longitude: number;
  address: string;
  isBlocked: boolean;
  isDeleted: boolean;
  repairWorkshop: boolean;
  tradeInStore: boolean;
  recyclingContainer: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  id: string;
  acceptableEwaste?: string;
}

interface CollectionCenterResponseData {
  count: number;
  pages: number;
  data: CollectionCenterData[];
  message: string;
}

interface CommonResponseType {
  statusCode: number;
  message: string;
  data: CollectionCenterResponseData;
}


export const collectionCenterApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // collection centers
    getCollectionCenter: builder.query<CommonResponseType, void>({
      query: () => ({
        url: END_POINTS.getCollectionCenter,
        method: "GET",
        params: {
          page: 1,
          size: 50
        }
      }),
    }),
  })
})

export const {useGetCollectionCenterQuery} = collectionCenterApi;