import { useState, useEffect } from 'react';
import { CollectionCenterData } from '../services/admin/collectionCenter';

export const useGeolocation = () => {
  const [userLocation, setUserLocation] = useState<Partial<CollectionCenterData> | null>(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return userLocation;
}; 