interface FilterButtonsProps {
  filters: {
    repairWorkshop: boolean;
    tradeInStore: boolean;
    recyclingContainer: boolean;
  };
  setFilters: React.Dispatch<React.SetStateAction<{
    repairWorkshop: boolean;
    tradeInStore: boolean;
    recyclingContainer: boolean;
  }>>;
}

export const FilterButtons: React.FC<FilterButtonsProps> = ({ filters, setFilters }) => {
  return (
    <div className="map-controls__buttons">
      <button
        onClick={() => setFilters(prev => ({ ...prev, repairWorkshop: !prev.repairWorkshop }))}
        className={`filter-button filter-button--repair ${filters.repairWorkshop ? 'active' : ''}`}
      >
        Repair
      </button>

      <button
        onClick={() => setFilters(prev => ({ ...prev, tradeInStore: !prev.tradeInStore }))}
        className={`filter-button filter-button--trade-in ${filters.tradeInStore ? 'active' : ''}`}
      >
        Trade-in
      </button>

      <button
        onClick={() => setFilters(prev => ({ ...prev, recyclingContainer: !prev.recyclingContainer }))}
        className={`filter-button filter-button--recycling ${filters.recyclingContainer ? 'active' : ''}`}
      >
        Recycling
      </button>
    </div>
  );
}; 