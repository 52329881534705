import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import { useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";

import { CollectionCenterData, useGetCollectionCenterQuery } from "../../services/admin/collectionCenter";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { FilterButtons } from "../../components/button/filterButtons";
import { LocationPopup } from "../../components/chatMap/locationPopup";
import { useSearchParams } from "react-router-dom";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_BOX_API_KEY || '';

const FindBinMap = () => {
  const [searchParams] = useSearchParams();
  const userLocation = {
    longitude: Number(searchParams.get('lng')) || 55.481747846041145,
    latitude: Number(searchParams.get('lat')) || 25.3233379650232,
  }

  const { data } = useGetCollectionCenterQuery(undefined, { 
    selectFromResult: ({data}) => ({data: data?.data?.data})
  });
  
  const { filters, setFilters, filteredLocations } = useLocationFilters(data);
  const [selectedLocation, setSelectedLocation] = useState<CollectionCenterData | null>(null);

  const defaultLocation = {
    longitude: userLocation.longitude || 55.481747846041145,
    latitude: userLocation.latitude || 25.3233379650232,
    zoom: 9
  };

  return (
    <ReactMapGL
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      initialViewState={defaultLocation}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      style={{width: '100vw', height: '100vh'}}
    >
      <div className="map-controls">
        <FilterButtons filters={filters} setFilters={setFilters} />
        <NavigationControl position="bottom-right" style={{ marginBottom: '20px', marginRight: '20px' }} />
      </div>

      {filteredLocations?.map((location) => (
        <Marker 
          key={location.id}
          longitude={location.latitude} 
          latitude={location.longitude} 
          anchor="bottom"
          offset={[0, -10]}
          style={{cursor: 'pointer'}}
          color={
            location.repairWorkshop ? '#4CAF50' :
            location.tradeInStore ? '#2196F3' :
            location.recyclingContainer || (!location.repairWorkshop && !location.tradeInStore && !location.recyclingContainer) ? '#ee9a08' :
            'gray'
          }
          onClick={e => {
            e.originalEvent.stopPropagation();
            setSelectedLocation(location);
          }}
        />
      ))}

      {selectedLocation && (
        <LocationPopup 
          location={selectedLocation} 
          onClose={() => setSelectedLocation(null)} 
        />
      )}

      {userLocation && userLocation.longitude && userLocation.latitude && (
        <Marker 
          longitude={userLocation.longitude} 
          latitude={userLocation.latitude} 
          anchor="bottom"
          offset={[0, -10]}
          style={{cursor: 'pointer'}}
          color="red"
        />
      )}
    </ReactMapGL>
  );
};

export default FindBinMap;
